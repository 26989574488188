<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>
            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Общая_информация")}}</div>

                                    <!--поле Журнал уникальных номеров-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Журнал_уникальных_номеров")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode || !dataSource.Data.Object.is_new_record" class="onlyReadData">
                                                {{ dataSource.Data.Object.IsForUniqueNumber ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                :input-value="dataSource.Data.Object.IsForUniqueNumber"
                                                @change="onIsForUniqueNumberChange"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле Журнал уникальных номеров-->
                                    <v-row v-if="dataSource.Data.Object.IsForUniqueNumber === false" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Журнал_регистрации_ГУ")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode || !dataSource.Data.Object.is_new_record" class="onlyReadData">
                                                {{ dataSource.Data.Object.IsGovService ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                :input-value="dataSource.Data.Object.IsGovService"
                                                @change="onIsGovService"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле Категория лица-->
                                    <v-row v-if="dataSource.Data.Object.IsForUniqueNumber === false" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Категория_лица")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode || !dataSource.Data.Object.is_new_record" class="onlyReadData">
                                                {{ dataSource.Data.Object.DeclarantCategory ? $t(declarantCategories.find(x => x.id === dataSource.Data.Object.DeclarantCategory).Value) : $t("Не_указано") }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="declarantCategories.map(x => ({ id: x.id, Value: $t(x.Value) }))"
                                                item-value="id"
                                                item-text="Value"
                                                :value="dataSource.Data.Object.DeclarantCategory"
                                                @input="update({ property: 'Data.Object.DeclarantCategory', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                clearable
                                                @click:clear="update({ property: 'Data.Object.DeclarantCategory', value: 0 })"
                                                :rules="integerNotNullRule"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!--поле Год-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Год")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Year }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="years"
                                                :value="dataSource.Data.Object.Year"
                                                @input="update({ property: 'Data.Object.Year', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!--поле Наименование-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Наименование")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Name ? dataSource.Data.Object.Name : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Name"
                                                @input="update({ property: 'Data.Object.Name', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Параметры_номера")}}</div>

                                    <!--поле Индекс-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Индекс")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Index ? dataSource.Data.Object.Index : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Index"
                                                @input="update({ property: 'Data.Object.Index', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Счетчик-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Счетчик")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Value ? dataSource.Data.Object.Value : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Value"
                                                @input="update({ property: 'Data.Object.Value', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                type="number"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Шаблон номера-->
                                    <v-row v-if="dataSource.Data.Object.IsForUniqueNumber === false" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Шаблон_номера")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Template ? dataSource.Data.Object.Template : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :readonly="true"
                                                :value="dataSource.Data.Object.Template"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="mdi-dots-horizontal"
                                                @click:append="onTemplateSelect"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Резерв-->
                                    <v-row v-if="dataSource.Data.Object.IsForUniqueNumber === false" no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Резерв")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.Object.CounterReserve ? dataSource.Data.Object.CounterReserve.Items.map(x => x.Value).join(", ") : $t("Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>

                <TemplateNumberDlg ref="TemplateNumberDlgRef" />

            </v-tab-item>
        </v-tabs-items>

    </div>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import TemplateNumberDlg from '@/components/dialogs/TemplateNumberDlg';
import i18n from '@/i18n';
import _ from 'lodash';

export default {
    name: "EuolCounter",
    components: {
       Toolbar,
       TemplateNumberDlg
    },
    props: {
        id: {
            type: String,
            default: null
        },
        enterpriseId: {
            type: String,
            default: null
        }
    },
    data: () => ({
        dataSource: null,
        tab: null,
        templateItems: [
            '[СЧЕТЧИК]',
            '[ИНДЕКС ЖУРНАЛА]',
            '[ГОД - 2 ЦИФРЫ]',
            '[ГОД - 4 ЦИФРЫ]',
            '[ЗАЯВИТЕЛЬ]'
        ]
    }),
    computed: {
        ...mapGetters('references', { declarantCategories: 'GetDeclarantCategoryTypes' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            integerNotNullRule: 'getIntegerNotNullRule'
        }),
        isViewMode() {
            return this.dataSource?.Data.FormId === "0902021";
        },
        years() {
            return Array.from({ length: this.$moment().year() - 1999 }, (_, i) => i + 2000).reverse();
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async onToolbarClick (event, button) {
            let action = this[button.Action];
            
            if (action && typeof action === 'function') {
                if (button.Action == 'Save' || button.Action == 'SaveAndClose') {
                    let validate_result = this.$refs.form.validate();

                    if (validate_result)
                        await action(event, button);
                    else
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                }
                else
                    await action(event, button);
            }
            else
                this.$notify.alert(`Действие ${button.Action} не реализовано.`);
        },
        onIsForUniqueNumberChange(value) {
            this.updateSource({ property: 'Data.Object.IsForUniqueNumber', value });
            this.updateSource({ property: 'Data.Object.IsGovService', value: false });
            
            if (value) {
                this.updateSource({ property: 'Data.Object.DeclarantCategory', value: 0 });
                this.updateSource({ property: 'Data.Object.Template', value: '[СЧЕТЧИК]' });
            }
        },
        onIsGovService(value) {
            this.updateSource({ property: 'Data.Object.IsGovService', value });
            this.updateSource({ property: 'Data.Object.IsForUniqueNumber', value: false });
        },
        async onTemplateSelect() {
            try
            {
                let template = await this.$refs.TemplateNumberDlgRef.open(this.dataSource.Data.Object.Template, this.templateItems);
                this.updateSource({ property: 'Data.Object.Template', value: template });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        updateSource(event) {
            this.$_.set(this.dataSource, event.property, event.value);
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250),
        //#region Toolbar
        async Edit() {
            this.setOverlayVisible({ visible: true });

            let response = await httpAPI({
                url: `api/edit?type=CitizenStatements.EuolCounter&id=${this.dataSource.Data.Object.id}&entid=${this.enterpriseId}`,
                method: 'GET'
            });

            if (response) {
                this.dataSource.Data.Menu = response.data.payload.Data.Menu;
                this.dataSource.Data.FormId = response.data.payload.Data.FormId;
            }

            this.setOverlayVisible({ visible: false });
        },
        async SaveAndClose() {
            this.setOverlayVisible({ visible: true });

            let response = await httpAPI({
                url: `api/save?type=CitizenStatements.EuolCounter&entid=${this.enterpriseId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content : JSON.stringify({ parent: this.dataSource.Data.Object, childrens: null }) },
            });

            if (response) {
                this.dataSource = response.data.payload;
                this.$notify.success(response.data.payload?.Message ?? response.data.message);
            }

            this.setOverlayVisible({ visible: false });
        },
        async Reserve(event, button) {
            this.setOverlayVisible({ visible: true });
            let reservedValue = this.dataSource.Data.Object.Value;

            let response = await httpAPI({
                url: `api/settings/counterreserve?id=${this.dataSource.Data.Object.id}&value=${reservedValue}&isEuol=${button.Type === 'CitizenStatements.EuolCounter'}&entid=${this.enterpriseId}`,
                method: 'GET',
            });
            
            if (response) {

                var openResponce = await httpAPI({
                    url: `api/open?type=${button.Type}&id=${this.dataSource.Data.Object.id}&entid=${this.enterpriseId}`,
                    method: 'GET'
                });

                if (openResponce)
                    this.dataSource = openResponce.data.payload;

                this.$notify.success(response.data.payload.Message);
            }

            this.setOverlayVisible({ visible: false });
        },
        //#endregion
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.validate();
        }  
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs.form.validate();
        })();
    },
    beforeRouteEnter(to, from, next) {
        httpAPI({
            url: to.query.id === 'new'
            ? `api/new?type=CitizenStatements.EuolCounter&entid=${to.params.enterpriseId}`
            : `api/open?type=CitizenStatements.EuolCounter&id=${to.query.id}&entid=${to.params.enterpriseId}`,
            method: 'GET'
        })
        .then(response => {
            if (response)
                next(vm => { vm.dataSource = response.data.payload; });

            next(false);
        });
    }
}
</script>